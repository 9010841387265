import React from "react"
import Layout from "@components/layout"
import { GetPMLP } from "@components/getpowermetrics/get-pm-lp"

const ConsistencyOne = () => {
  return (
    <Layout
      titleExtension="Klipfolio PowerMetrics"
      seo={{
        title: "Define metrics once and use everywhere.",
        description: `PowerMetrics helps data teams unlock the value in their data stack and make working with metrics effortless for everyone. Provide a catalog of trusted metrics.`,
        tracking: ["noindex", "nofollow", "noarchive"],
      }}
      fullWidth
      marginless
      hideOverflow
      microsite
      hideNav
    >
      <GetPMLP
        header={
          <>
            Define metrics once
            <br />
            and use everywhere.
          </>
        }
        description={`No more data duplication and inconsistencies. Centrally stored metrics give business users the trusted data they need to work with confidence.`}
        lp
      />
    </Layout>
  )
}

export default ConsistencyOne
